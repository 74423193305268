import React from 'react';
import type { ReactNode } from 'react';
import type { ModalProps } from '@quno/patient-journey/src/components/ui/QdModal';

export type ModalContextProps = Omit<ModalProps, 'children' | 'onClose'> & {
  content?: ReactNode;
  isPaymentModal?: boolean;
  isFlightUploadModal?: boolean;
  isPrevButtonClicked?: boolean;
  isOpen?: boolean;
  onModalClose?: () => void;
};

export type ModalContext = ModalContextProps | null;

type ModalContextType = {
  setModal: (modal: ModalContext | null) => void;
  modal: ModalContext | null;
};

export const ModalContext = React.createContext<ModalContextType>({
  setModal() {
    return;
  },
  modal: null,
});
