import clsx from 'clsx';
import styles from './Spinner.module.scss';
import type { BaseVariant } from '../types/qds';

type Props = {
  /**
   * Classname(s) added to the respective component
   * @default ''
   */
  classes?: string;
  /**
   * if `true` the spinner will use an animation while rotating
   * @default false
   */
  dash?: boolean;
  /**
   * The diameter of the spinner in pixels
   * @default 16
   */
  size?: number;
  /**
   * The stroke thickness of the spinner in pixels
   * @default 3
   */
  thickness?: number;
  /**
   * The style used for the spinner
   * @default 'primary'
   */
  variant?: BaseVariant;
  /**
   * Classname(s) added to the respective component's wrapper element
   * @default ''
   */
  wrapperClasses?: string;
};

export const Spinner = ({
  classes = '',
  dash,
  size = 16,
  thickness = 3,
  variant = 'primary',
  wrapperClasses = '',
}: Props): JSX.Element => {
  return (
    <span
      className={clsx(
        styles.spinner,
        styles[variant],
        wrapperClasses,
        dash ? styles.dash : styles.rotate,
      )}
      style={{ height: `${size + 4}px`, width: `${size + 4}px` }}
    >
      <svg
        className={classes}
        viewBox={`${size / 2} ${size / 2} ${size} ${size}`}
      >
        <circle
          cx={size}
          cy={size}
          r={(size - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
        />
      </svg>
    </span>
  );
};
