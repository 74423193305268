import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from '@quno/patient-journey/src/hooks/useTranslation';
import { Button } from '@quno/qds/Button';
import styles from './QdModal.module.scss';
import type { ReactNode } from 'react';

export type ModalProps = {
  backdrop?: boolean;
  backdropBlur?: boolean;
  children: ReactNode;
  onClose: () => void;
  open?: boolean;
  showPrevIcon?: boolean;
  showCloseIcon?: boolean;
  closeButtonText?: string;
  visuallyHidden?: boolean;
  belowHeader?: boolean;
  isMultiStep?: boolean;
  title?: string;
  currentProgress?: number;
  onStepBack?: () => void;
  activeSlide?: number;
  useTransition?: boolean;
  showCloseButton?: boolean;
  footer?: string | ReactNode;
  className?: string;
  fullScreen?: boolean;
};

const CONTENT_VISIBILITY_TIMEOUT_MS = 100;

const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      backdrop = true,
      backdropBlur,
      children,
      onClose,
      open,
      showPrevIcon = true,
      showCloseIcon = true,
      visuallyHidden,
      belowHeader,
      isMultiStep,
      title = '',
      currentProgress = 0,
      onStepBack,
      activeSlide,
      showCloseButton = true,
      footer,
      className,
      closeButtonText,
      fullScreen,
      ...props
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(open);
    const [showContent, setShowContent] = useState(false);
    const [progress] = useState(currentProgress);
    const { t } = useTranslation();
    const closeText = closeButtonText ?? t('close');

    useEffect(() => {
      setIsOpen(open);
      if (open) {
        document.body.classList.add('overflow-hidden');
      } else {
        isLastModal();
      }

      const openTimeout = setTimeout(
        () => setShowContent(true),
        CONTENT_VISIBILITY_TIMEOUT_MS,
      );

      return () => clearTimeout(openTimeout);
    }, [open]);

    const isLastModal = (): void => {
      if (document.querySelectorAll('.QdModalContent.isOpen').length === 1) {
        document.body.classList.remove('overflow-hidden');
      }
    };

    const handleArrowClick = (): void => {
      // eslint-disable-next-line
      // @ts-ignore
      if (isMultiStep && activeSlide > 0) {
        onStepBack?.();
        return;
      }

      handleClose();
      return;
    };

    const handleClose = (): void => {
      setShowContent(false);
      isLastModal();

      setTimeout(() => {
        setIsOpen(false);
        onClose?.();
      }, CONTENT_VISIBILITY_TIMEOUT_MS);
    };

    return (
      <>
        {backdrop && (
          <div
            className={clsx(
              belowHeader ? styles.z20 : [styles.z30, styles.backdrop],
              backdropBlur && styles.backdropBlur,
            )}
            onClick={handleClose}
          />
        )}
        <div
          {...props}
          className={clsx(
            'QdModalContent',
            styles.content,
            (showContent || (visuallyHidden && isOpen)) && 'isOpen',
            className,
            fullScreen && styles.fullScreen,
          )}
          ref={ref}
        >
          {isMultiStep && (
            <div className={styles.progressBar}>
              <div
                className={styles.progress}
                style={{ width: `${progress || currentProgress}%` }}
              />
            </div>
          )}
          {showPrevIcon && (
            <button
              className={styles.prevIcon}
              onClick={handleArrowClick}
              title={t('back')}
              data-type="back"
            >
              <span className={styles.prevIconSvg}>
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66 2.11C8.15 1.59 8.10 0.78 7.55 0.32C7.00 -0.14 6.15 -0.09 5.67 0.42L0.33 6.15C-0.11 6.63 -0.11 7.36 0.33 7.84L5.67 13.57C6.15 14.09 7.00 14.14 7.55 13.67C8.10 13.21 8.15 12.40 7.66 11.88L3.11 7L7.66 2.11Z"
                    fill="#585A51"
                  />
                </svg>
              </span>
              <span>{t('back')}</span>
            </button>
          )}
          {showCloseIcon && (
            <button
              className={styles.closeIcon}
              onClick={handleClose}
              title="Close"
              data-type="close"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 6.55L13.76 0.79C14.15 0.40 14.80 0.40 15.20 0.79C15.59 1.19 15.59 1.84 15.20 2.23L9.44 8L15.20 13.76C15.59 14.15 15.59 14.80 15.20 15.20C14.80 15.59 14.15 15.59 13.76 15.20L8 9.44L2.23 15.20C1.84 15.59 1.19 15.59 0.79 15.20C0.40 14.80 0.40 14.15 0.79 13.76L6.55 8L0.79 2.23C0.40 1.84 0.40 1.19 0.79 0.79C1.19 0.40 1.84 0.40 2.23 0.79L8 6.55Z"
                  fill="#282828"
                />
              </svg>
            </button>
          )}
          <div className={styles.innerContent}>
            {title && <h2 className={styles.title}>{title}</h2>}

            {children}
            {showCloseButton && !footer ? (
              <div className={styles.closeButtonWrapper}>
                <Button
                  onClick={handleClose}
                  variant="secondary"
                  className={styles.closeButton}
                >
                  {closeText}
                </Button>
              </div>
            ) : null}
            {footer && <div className={styles.footer}>{footer}</div>}
          </div>
        </div>
      </>
    );
  },
);

Modal.displayName = 'Modal';
export default Modal;
