import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import QdModal from '@quno/patient-journey/src/components/ui/QdModal';
import SlideOut from '@quno/patient-journey/src/components/ui/SlideOut';
import { ModalContext } from '@quno/patient-journey/src/context/ModalContext';
import styles from './Modal.module.scss';

export const Modal = (): JSX.Element | null => {
  const slideOutModalRef = useRef(null);
  const { modal, setModal } = useContext(ModalContext);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (modal?.isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [modal?.isOpen]);

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 1023px)').matches);
  }, []);

  if (!modal || !modal.isOpen) {
    return null;
  }

  // To solve "React does not recognize the `isOpen` prop on a DOM element" error
  const { isPrevButtonClicked, isOpen, fullScreen, onModalClose, ...rest } =
    modal;

  if (isMobile) {
    return createPortal(
      <SlideOut
        className={clsx('bookings-modal', styles.slideOutModal)}
        onClose={() => {
          onModalClose?.();
          setModal({ content: null, isOpen: false });
        }}
        open={modal.isOpen}
        placement="bottom"
        showCloseIcon
        ref={slideOutModalRef}
        lazyLoad={false}
        closeButtonText={modal.closeButtonText}
        noTransitionWrapper
        onStepBack={() => setModal({ ...modal, isPrevButtonClicked: true })}
        {...rest}
      >
        <div className={styles.modalContentMobile}>{modal.content}</div>
      </SlideOut>,
      // eslint-disable-next-line
      // @ts-ignore
      document.getElementById('modal-container'),
    );
  }

  return createPortal(
    <QdModal
      backdropBlur
      className={clsx(
        'bookings-modal',
        styles.qdModal,
        fullScreen ? styles.fullScreenModal : styles.notFullScreenModal,
      )}
      onClose={() => {
        onModalClose?.();
        setModal({ content: null, isOpen: false });
      }}
      open={modal.isOpen}
      showCloseIcon={!modal.isPaymentModal}
      ref={slideOutModalRef}
      closeButtonText={modal.closeButtonText}
      onStepBack={() => setModal({ ...modal, isPrevButtonClicked: true })}
      fullScreen={fullScreen}
      {...rest}
    >
      <div
        className={clsx(
          styles.modalContentDesktop,
          fullScreen ? styles.fullScreenContent : styles.notFullScreenContent,
        )}
      >
        {modal.content}
      </div>
    </QdModal>,
    // eslint-disable-next-line
    // @ts-ignore
    document.getElementById('modal-container'),
  );
};
