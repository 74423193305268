import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useTranslation } from '@quno/patient-journey/src/hooks/useTranslation';
import { Button } from '@quno/qds/Button';
import Icon from './Icon';
import styles from './SlideOut.module.scss';
import type { ReactNode } from 'react';

type Props = {
  activeSlide?: number;
  backdrop?: boolean;
  belowHeader?: boolean;
  children: ReactNode;
  currentProgress?: number;
  footer?: string | ReactNode;
  isMultiStep?: boolean;
  lazyLoad?: boolean;
  onClose?: () => void;
  onStepBack?: () => void;
  open: boolean;
  placement?: 'bottom' | 'left' | 'right' | 'top';
  roundedCorners?: boolean;
  showCloseIcon?: boolean;
  showCloseButton?: boolean;
  showPrevIcon?: boolean;
  showProgressBar?: boolean;
  title?: string;
  visuallyHidden?: boolean;
  className?: string;
  closeButtonText?: string;
  noTransitionWrapper?: boolean;
};

const CONTENT_VISIBILITY_TIMEOUT_MS = 500;

const SlideOut = forwardRef<HTMLElement, Props>(
  (
    {
      backdrop = true,
      children,
      isMultiStep,
      onClose,
      onStepBack,
      open,
      placement = 'left',
      showPrevIcon,
      showCloseIcon = true,
      showCloseButton,
      visuallyHidden,
      lazyLoad = true,
      belowHeader,
      title,
      footer,
      activeSlide,
      showProgressBar,
      currentProgress = 0,
      roundedCorners,
      className,
      closeButtonText,
      noTransitionWrapper,
      ...props
    },
    ref,
  ): JSX.Element => {
    const [isOpen, setIsOpen] = useState(open);
    const [showContent, setShowContent] = useState(false);
    const [progress] = useState(currentProgress);
    const { t } = useTranslation();
    const closeText = closeButtonText ?? t('close');

    useEffect(() => {
      setIsOpen(open);
      if (true === open) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }

      const openTimeout = setTimeout(
        () => setShowContent(true),
        CONTENT_VISIBILITY_TIMEOUT_MS,
      );

      return () => clearTimeout(openTimeout);
    }, [open]);

    const handleClose = (): void => {
      setShowContent(false);

      setTimeout(() => {
        setIsOpen(false);

        document.body.classList.remove('overflow-hidden');
        onClose && onClose();
      }, CONTENT_VISIBILITY_TIMEOUT_MS);
    };

    const handleArrowClick = (): void => {
      // eslint-disable-next-line
      // @ts-ignore
      if (isMultiStep && activeSlide > 0) {
        onStepBack?.();
      } else {
        setIsOpen(false);
        if (document.querySelectorAll('.SlideOutContent.isOpen').length === 1) {
          document.body.classList.remove('overflow-hidden');
          document.body.classList.remove('fixed');
          document.documentElement.classList.remove('overflow-hidden');
        }

        onClose?.();
      }
    };

    return (
      <>
        {backdrop && (
          <div
            className={clsx(
              styles.backdropContainer,
              belowHeader ? styles.z20 : [styles.z50, styles.SlideOutBackdrop],
            )}
            onClick={handleClose}
          />
        )}
        <div
          {...props}
          className={clsx(
            styles.SlideOutContent,
            placement === 'right' && styles.rightPlacement,
            placement === 'left' && styles.leftPlacement,
            placement === 'top' && styles.topPlacement,
            placement === 'bottom' && styles.bottomPlacement,
            (showContent || (visuallyHidden && isOpen)) && styles.isOpen,
            roundedCorners && styles.SlideOutContentRounded,
            className,
          )}
          // eslint-disable-next-line
          // @ts-ignore
          ref={ref}
          style={{ zIndex: 51 }}
        >
          {isMultiStep && showProgressBar && (
            <div className={styles.progressContainer}>
              <div
                className={styles.progress}
                style={{ width: `${progress || currentProgress}%` }}
              />
            </div>
          )}
          {showPrevIcon && (
            <button
              className={styles.prevButton}
              onClick={handleArrowClick}
              title="Back"
            >
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.66 2.11C8.15 1.59 8.10 0.78 7.55 0.32C7.00 -0.14 6.15 -0.09 5.67 0.42L0.33 6.15C-0.11 6.63 -0.11 7.36 0.33 7.84L5.67 13.57C6.15 14.09 7.00 14.14 7.55 13.67C8.10 13.21 8.15 12.40 7.66 11.88L3.11 7L7.66 2.11Z"
                  fill="#585A51"
                />
              </svg>
            </button>
          )}
          {showCloseIcon && (
            <button
              className={styles.closeBtn}
              onClick={handleClose}
              title={t('close')}
            >
              <Icon type="Cross" />
            </button>
          )}
          {title && <h2 className={styles.headline}>{title}</h2>}
          {lazyLoad ? <LazyLoad once>{children}</LazyLoad> : children}
          {showCloseButton && !footer ? (
            <div className={styles.closeButton}>
              <Button onClick={handleClose} variant="secondary" fullWidth>
                {closeText}
              </Button>
            </div>
          ) : null}
          {footer && <div className={styles.footer}>{footer}</div>}
        </div>
      </>
    );
  },
);

SlideOut.displayName = 'SlideOut';
export default SlideOut;
