import { stringify } from 'querystring';
import type { queryMapper } from '@quno/patient-journey/pages/api/data';

export type FetchContentfulApiOptions<TVariables> = {
  query: keyof typeof queryMapper;
  variables: TVariables;
  preview?: boolean;
};

export interface GraphQLError {
  message: string;
  locations?: {
    line: number;
    column: number;
  }[];
  path?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extensions?: any;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface GraphQLResponse<T = any> {
  data?: T;
  errors?: GraphQLError[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extensions?: any;
  status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const fetchContentfulApi = async <TQuery, TVariables>({
  query,
  variables,
  preview = false,
}: FetchContentfulApiOptions<TVariables>): Promise<GraphQLResponse<TQuery>> => {
  const res = await fetch(`/api/data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables,
      preview,
    }),
  }).catch((error) => {
    console.error(stringify(error));
  });

  if (!res?.ok) {
    throw new Error(`${res?.status}: ${res?.statusText}`);
  }

  const result = await res?.json();

  if (result?.errors) {
    console.error(`${query} Some error has ocurred`);
  }

  return result;
};
